.App {
  /*text-align: center;*/
  margin: 20px;
}




.MuiDropzoneArea-root{
  /*display: flex;*/
  /*align-items: center;*/
  background-color: #fbfcff !important;
  color: #2c3345;
  border: 1px dashed #c3cad8 !important;
}

.testsContainer {
  display:flex;
  width: inherit;
  max-width: 1000px;
  flex-direction:row;
  padding:5px;
}

.testSelection {
  width: 50%;
  flex:1 1 auto;
  text-align:center;
  margin:5px;
  box-shadow: 17px 24px 29px -21px rgba(0,0,0,0.45);
  -webkit-box-shadow: 17px 24px 29px -21px rgba(0,0,0,0.45);
  -moz-box-shadow: 17px 24px 29px -21px rgba(0,0,0,0.45);
  display: grid;
  align-content: space-between;
  background: #eeeeee;
  padding: 10px;
  border-radius: 10px;
}

@media ( max-width: 700px) {
  .testsContainer {
    flex-wrap: wrap;
  }
  .testSelection{
    width: 100%;
    justify-items : center;
  }
  .testFeatures {
    width : 55vw;
  }
}

.centerEverything {
  width: 300px;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -150px;
  margin-top: -150px;
}

.MuiDropzoneArea-textContainer{
  /*width: inherit;*/
}

.fixButton{
  padding: 6px 16px !important;
  font-size: 0.875rem !important;
  min-width: 20px !important;
  box-sizing: border-box !important;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important ;
  font-weight: 500 !important;
  line-height: 1.75 !important;
  border-radius: 4px !important;
  letter-spacing: 0.02857em !important;
  text-transform: uppercase !important;
}

.primaryColor{
  background-color:#3f51b5 !important;
}

.MuiPhoneNumber-flagButton {
  background-color: inherit !important;
  color: rgba(0, 0, 0, 0.87) !important;
  padding: 6px 16px !important;
  font-size: 0.875rem !important;
  min-width: 20px !important;
  box-sizing: border-box !important;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important ;
  font-weight: 500 !important;
  line-height: 1.75 !important;
  border-radius: 4px !important;
  letter-spacing: 0.02857em !important;
  text-transform: uppercase !important;
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;

  color: inherit;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

}
